{ // GLOBAL Menu
  const btnMainElements = document.querySelectorAll(".btn-main");
  btnMainElements.forEach((element) => {
    element.setAttribute("role", "button");
  });
  //Menu Mobile
  document.addEventListener("DOMContentLoaded", () => {
    const toggleButtons = document.querySelectorAll(".nav-menu__toggle");
    const overlay = document.querySelector(".menu-block-overlay");
  
    // Função para alternar a visibilidade do overlay
    function toggleOverlay() {
      overlay.classList.toggle("active");
    }
  
    // Adiciona o evento de clique a todos os botões de toggle
    toggleButtons.forEach((button) => {
      button.addEventListener("click", toggleOverlay);
    });
  
    // Fecha o menu (remove a classe 'active') quando a tecla Esc é pressionada
    document.addEventListener("keydown", (event) => {
      if (event.key === "Escape" && overlay.classList.contains("active")) {
        document.querySelector(".nav-menu__toggle").click();
      }
    });
  
    // Fecha o menu (remove a classe 'active') quando o overlay é clicado
    overlay.addEventListener("click", () => {
      if (overlay.classList.contains("active")) {
        document.querySelector(".nav-menu__toggle").click();
      }
    });
  });
  
}
